import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

export default function FooterList(props: Props) {
  const { children } = props;

  return <ul className="flex flex-col gap-5">{children}</ul>;
}
