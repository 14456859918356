'use client';

import Image, { ImageProps } from 'next/image';
import { useEffect, useState } from 'react';

import { getImageLoader } from '@/utils/image-loaders';
import { IMAGE_PLACEHOLDER } from '@/utils/image-placeholder';

export function ImageWithPlaceholder({
  alt,
  src,
  placeholder,
  blurDataURL,
  sizes,
  ...otherProps
}: ImageProps) {
  const [imgSrc, setImgSrc] = useState(src);

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  return (
    <Image
      src={imgSrc}
      alt={alt}
      placeholder={placeholder || 'blur'}
      blurDataURL={blurDataURL || IMAGE_PLACEHOLDER}
      loader={getImageLoader(imgSrc)}
      onLoad={(e) => {
        if ((e.target as HTMLImageElement).naturalWidth === 0) {
          setImgSrc(IMAGE_PLACEHOLDER);
        }
      }}
      onError={() => setImgSrc(IMAGE_PLACEHOLDER)}
      sizes={sizes || '100vw'}
      {...otherProps}
    />
  );
}
