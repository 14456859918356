import { tiemposFont } from '@/theme/tiemposFont';

import clsx from 'clsx';

export default function PrimaryHeading({
  children,
  className,
  ...divProps
}: React.ComponentPropsWithoutRef<'h1'>) {
  return (
    <h1
      className={clsx(
        tiemposFont.className,
        'text-4xl font-medium tracking-[0.35px]',
        className
      )}
      {...divProps}
    >
      {children}
    </h1>
  );
}
