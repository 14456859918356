import { ScreenSize, useScreenSize } from '@/hooks/useScreenSize';

export function ClickablePhoneNumber({
  phoneNumber,
}: {
  phoneNumber: React.ReactNode;
}) {
  const screenSize = useScreenSize();
  const isMobile = screenSize < ScreenSize.SM;

  return isMobile ? (
    <a className="font-semibold" href={`tel:${phoneNumber}`}>
      {phoneNumber}
    </a>
  ) : (
    <strong>{phoneNumber}</strong>
  );
}
