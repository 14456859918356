import { CircleLoader } from '../CircleLoader';

import clsx from 'clsx';

type SecondaryButtonProps = {
  sm?: boolean;
  isLoading?: boolean;
} & React.ComponentPropsWithoutRef<'button'>;

export function SecondaryButton(props: SecondaryButtonProps) {
  const { sm, className, isLoading, children, ...otherProps } = props;

  return (
    <button
      {...otherProps}
      className={clsx(
        className,
        'font-medium rounded-full border-2 px-5 py-2  disabled:bg-grey-90 disabled:border-grey-90 disabled:text-grey-25',
        'text-teal-40 border-teal-40 hover:bg-teal-40 hover:text-white group',
        sm ? 'text-sm leading-none' : 'text-lg'
      )}
    >
      <div className="flex items-center justify-center gap-2">
        {isLoading && (
          <CircleLoader className="fill-teal-40 group-hover:fill-white" />
        )}
        {children}
      </div>
    </button>
  );
}
