import { ReactNode } from 'react';

import { Link } from '@/components/Link';
import { Locale } from '@/utils/type-utils';

type Props = {
  href: string;
  children: ReactNode;
  dataTestId: string;
  locale?: Locale;
  isExternal?: boolean;
  onClick?: () => void;
};

export default function FooterListItemLink({
  href,
  children,
  dataTestId,
  isExternal,
  locale,
  onClick,
}: Props) {
  return (
    <li>
      <Link
        href={href}
        data-testid={dataTestId}
        className="font-light hover:underline"
        rel={isExternal ? 'noopener' : ''}
        target={isExternal ? '_blank' : '_self'}
        locale={locale}
        onClick={onClick}
      >
        {children}
      </Link>
    </li>
  );
}
