import { useTranslations } from 'next-intl';

import List from './List';
import ListItemLink from './ListItemLink';
import Title from './ListTitle';
import Section from './Section';

export default function IndustriousSection() {
  const t = useTranslations('footer');

  return (
    <Section>
      <Title>{t('industrious')}</Title>
      <List>
        <ListItemLink href="/about-us" dataTestId="link-about">
          {t('about')}
        </ListItemLink>
        <ListItemLink href="/locations" dataTestId="link-locations">
          {t('locations')}
        </ListItemLink>
        <ListItemLink href="/about-us#faqs" dataTestId="link-faq">
          {t('faq')}
        </ListItemLink>
        <ListItemLink href="/about-us#team" dataTestId="link-team">
          {t('team')}
        </ListItemLink>
        <ListItemLink href="/blog" dataTestId="link-blog">
          {t('blog')}
        </ListItemLink>
      </List>
    </Section>
  );
}
