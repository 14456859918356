import { useTranslations } from 'next-intl';
import Image from 'next/image';

import facebookIcon from '@/public/icons/facebookIcon.svg';
import instagramIcon from '@/public/icons/instagramIcon.svg';
import linkedinIcon from '@/public/icons/linkedinIcon.svg';
import twitterIcon from '@/public/icons/twitterIcon.svg';

import ListItemLink from './ListItemLink';

export default function SocialModialList() {
  const t = useTranslations('footer');

  return (
    <>
      <style>
        {`.icon:hover {
            /* color yellow #eab308 */
            filter: brightness(0) saturate(100%) invert(73%) sepia(29%) saturate(4588%)
              hue-rotate(8deg) brightness(104%) contrast(94%);
          
        }`}
      </style>
      <ul className="flex gap-2">
        <ListItemLink
          href="https://www.facebook.com/IndustriousOffice"
          dataTestId="link-facebook"
        >
          <Image
            className="icon"
            src={facebookIcon}
            alt={t('alt-icon-facebook')}
          />
        </ListItemLink>
        <ListItemLink
          href="https://twitter.com/industrioushq"
          dataTestId="link-twitter"
        >
          <Image
            className="icon"
            src={twitterIcon}
            alt={t('alt-icon-twitter')}
          />
        </ListItemLink>
        <ListItemLink
          href="https://www.instagram.com/industrioushq"
          dataTestId="link-instagram"
        >
          <Image
            className="icon"
            src={instagramIcon}
            alt={t('alt-icon-instagram')}
          />
        </ListItemLink>
        <ListItemLink
          href="https://www.linkedin.com/company/industrioushq"
          dataTestId="link-linkedin"
        >
          <Image
            className="icon"
            src={linkedinIcon}
            alt={t('alt-icon-linkedin')}
          />
        </ListItemLink>
      </ul>
    </>
  );
}
