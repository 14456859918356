import type {
  ComponentPropsWithoutRef,
  ElementType,
  PropsWithChildren,
} from 'react';

import clsx from 'clsx';

type AsProp<E extends ElementType> = {
  as?: E;
};

type ComponentProps<E extends ElementType> = PropsWithChildren<
  ComponentPropsWithoutRef<E> & AsProp<E>
>;

const defaultElement = 'p';

export default function BodyBoldUppercase<
  E extends ElementType = typeof defaultElement
>({ as, children, className, ...divProps }: ComponentProps<E>) {
  const Component = as ?? defaultElement;

  return (
    <Component
      className={clsx('text-base font-bold leading-6 uppercase', className)}
      {...divProps}
    >
      {children}
    </Component>
  );
}
