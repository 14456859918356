import { useTranslations } from 'next-intl';

import List from './List';
import ListItemLink from './ListItemLink';
import Title from './ListTitle';
import Section from './Section';
import SocialModialList from './SocialMediaList';

export default function ConnectSection() {
  const t = useTranslations('footer');

  return (
    <Section>
      <Title>{t('connect')}</Title>
      <List>
        <ListItemLink href="/contact-us" dataTestId="link-contact-us">
          {t('contact')}
        </ListItemLink>
        <ListItemLink
          href="/contact-us?prMediaForm=open"
          dataTestId="link-press"
        >
          {t('press')}
        </ListItemLink>
        <ListItemLink href="/careers" dataTestId="link-careers">
          {t('careers')}
        </ListItemLink>
        <li>
          <SocialModialList />
        </li>
      </List>
    </Section>
  );
}
