import clsx from 'clsx';

type CircleLoaderProps = {
  className?: string;
};

export function CircleLoader({ className }: CircleLoaderProps) {
  return (
    <svg
      className={clsx('animate-spin', className)}
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={22}
      fill="#fff"
    >
      <path
        fill="inherit"
        fillRule="evenodd"
        stroke="transparent"
        d="M10.107 17.985c-3.944 0-6.573-3.116-6.573-7.035 0-3.518 2.528-6.432 6.573-6.935V1C5.05 1.402.5 5.724.5 10.95.5 16.477 4.747 21 10.41 21c3.337 0 6.169-1.608 8.09-4.12l-2.73-1.508c-1.315 1.608-3.438 2.613-5.663 2.613Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
