import { tiemposFont } from '@/theme/tiemposFont';

import clsx from 'clsx';

type SecondaryHeadingProps = React.ComponentPropsWithoutRef<'h2'> & {
  fontFamilyClassName?: string;
};

export default function SecondaryHeading({
  fontFamilyClassName,
  children,
  className,
  ...divProps
}: SecondaryHeadingProps) {
  return (
    <h2
      className={clsx(
        'text-[28px] font-medium leading-9',
        fontFamilyClassName ?? tiemposFont.className,
        className
      )}
      {...divProps}
    >
      {children}
    </h2>
  );
}
