'use client';

import debounce from 'lodash.debounce';
import { useEffect, useState } from 'react';

const DEBOUNCE_TIME = 100;

export enum ScreenSize {
  XXS = 320,
  XS = 375,
  SM = 500,
  MD = 600,
  LG = 960,
  XL = 1440,
}

export function useScreenSize(): number {
  const [widthWindow, setWidthWindow] = useState<number>(0);

  useEffect(() => {
    const handleResize = debounce(() => {
      setWidthWindow(window.innerWidth);
    }, DEBOUNCE_TIME);

    setWidthWindow(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return widthWindow;
}
