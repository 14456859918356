import { useTranslations } from 'next-intl';

import List from './List';
import LinkItemLink from './ListItemLink';
import Title from './ListTitle';
import Section from './Section';

export default function SolutionSection() {
  const t = useTranslations('footer');

  return (
    <Section>
      <Title>{t('solutions')}</Title>
      <List>
        <LinkItemLink href="/solutions/offices" dataTestId="link-offices">
          {t('offices')}
        </LinkItemLink>
        <LinkItemLink href="/solutions/suites" dataTestId="link-suites">
          {t('suites')}
        </LinkItemLink>
        <LinkItemLink
          href="/solutions/coworking-membership"
          dataTestId="link-access"
        >
          {t('access')}
        </LinkItemLink>
        <LinkItemLink href="/solutions/virtual" dataTestId="link-virtual">
          {t('virtual')}
        </LinkItemLink>
        <LinkItemLink
          href="/solutions/conference-and-meeting-rooms"
          dataTestId="link-meetings"
        >
          {t('meetings')}
        </LinkItemLink>
      </List>
    </Section>
  );
}
