'use client';

import React, { useContext, useEffect, useState } from 'react';

import { MicroLocationDto } from '@/lib/locations/dto/micro-location.dto';
import { fetchIndustriousMicroLocations } from '@/lib/locations/location-api';

import useNearestLocation from './useNearestLocation';

type IndustriousMicroLocationsContextType = {
  locations: MicroLocationDto[];
  nearestLocation?: MicroLocationDto;
};

const IndustriousMicroLocationsContext =
  React.createContext<IndustriousMicroLocationsContextType>({
    locations: [],
  });

const IndustriousMicroLocationsProvider = ({
  locale,
  children,
}: {
  locale: string;
  children: React.ReactNode;
}) => {
  const [locations, setLocations] = useState<MicroLocationDto[]>([]);
  const { nearestLocation } = useNearestLocation({ locale });

  useEffect(() => {
    const fetchLocations = async () => {
      const locs: MicroLocationDto[] = await fetchIndustriousMicroLocations(
        locale
      );
      setLocations(locs);
    };

    fetchLocations();
  }, [locale]);

  return (
    <IndustriousMicroLocationsContext.Provider
      value={{ locations, nearestLocation }}
    >
      {children}
    </IndustriousMicroLocationsContext.Provider>
  );
};

const useIndustriousMicroLocations =
  (): IndustriousMicroLocationsContextType => {
    return useContext(IndustriousMicroLocationsContext);
  };

export { useIndustriousMicroLocations };
export default IndustriousMicroLocationsProvider;
