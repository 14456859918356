import { CircleLoader } from '../CircleLoader';

import clsx from 'clsx';

type PrimaryButtonProps = React.ComponentPropsWithoutRef<'button'> & {
  sm?: boolean;
  isLoading?: boolean;
};

export function PrimaryButton(props: PrimaryButtonProps) {
  const { className, sm, isLoading, children, ...otherProps } = props;

  return (
    <button
      {...otherProps}
      className={clsx(
        className,
        !isLoading &&
          'disabled:bg-grey-90 disabled:border-grey-90 disabled:text-grey-25',
        'font-medium rounded-full border-2 px-5 bg-teal-40 border-teal-40 text-white ',
        sm ? 'text-sm leading-none py-1' : 'text-lg py-2'
      )}
    >
      <div className="flex items-center justify-center gap-2">
        {isLoading && <CircleLoader />}
        {children}
      </div>
    </button>
  );
}
