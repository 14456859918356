import clsx from 'clsx';

type TertiaryButtonProps = {
  sm?: boolean;
} & React.ComponentPropsWithoutRef<'button'>;

export function TertiaryButton(props: TertiaryButtonProps) {
  const { sm, className, ...otherProps } = props;

  return (
    <button
      {...otherProps}
      className={clsx(
        className,
        'text-teal-40 hover:underlinefont-medium py-2 disabled:text-grey-25',
        sm ? 'text-sm leading-none' : 'text-lg'
      )}
    />
  );
}
