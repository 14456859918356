'use client';

import { useTranslations } from 'next-intl';

import { StatusCodeErrors } from '@/lib/error-codes.enum';
import { US_GENERIC_NUMBER } from '@/utils/constants';

import { ClickablePhoneNumber } from '../ClickablePhoneNumber';
import { Link } from '../Link';
import { RootLayout } from '../RootLayout';
import ErrorContent from './ErrorContent';

type PageErrorProps = {
  statusCode: StatusCodeErrors;
};

export default function PageError({ statusCode }: PageErrorProps) {
  const t = useTranslations(`page-error.${statusCode.toString()}`);

  return (
    <RootLayout>
      <ErrorContent
        statusCode={statusCode}
        altText={t('alt-text')}
        title={t('title')}
        description={t.rich('description', {
          important: (chunks) => <ClickablePhoneNumber phoneNumber={chunks} />,
          phoneNumber: US_GENERIC_NUMBER,
          link: (chunks) => (
            <Link className="underline" href="/contact-us">
              {chunks}
            </Link>
          ),
        })}
        ctaLabel={t('cta')}
      />
    </RootLayout>
  );
}
