'use client';

import { useTranslations } from 'next-intl';
import Image from 'next/image';

import { Link } from '@/components/Link';
import { useSupplier } from '@/hooks/useSupplier';
import VerticalLogo from '@/public/icons/verticalLogoWhiteIcon.svg';

import ConnectSection from './ConnectSection';
import IndustriousSection from './IndustriousSection';
import LanguageSection from './LanguageSection';
import MembersSection from './MembersSection';
import Newsletter from './Newsletter';
import PartnershipSection from './PartnershipSection';
import SolutionSection from './SolutionSection';

// TODO remove all the footer folder and rename FooterSC to Footer when it's used everywhere.
export function Footer() {
  const t = useTranslations('footer');

  const { isNuveenSupplier } = useSupplier();

  if (isNuveenSupplier) {
    return null;
  }

  return (
    <footer className="text-white bg-teal-40 py-14">
      <div className="base-container">
        <Newsletter />
        <hr className="mb-6" />
        <div className="grid grid-cols-2 gap-x-5 gap-y-10 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6">
          <IndustriousSection />
          <SolutionSection />
          <PartnershipSection />
          <ConnectSection />
          <MembersSection />
          <LanguageSection />
        </div>
        <div className="flex flex-col items-center gap-y-8 lg:flex-row lg:items-end justify-between mt-[48px] sm:mt-[70px]">
          <Link className="pb-[5px] lg:mb-0" href="/" data-testid="footer-logo">
            <Image src={VerticalLogo} alt="Industrious Logo" width={130} />
          </Link>
          <div className="flex flex-wrap justify-center gap-5 text-sm text-center gap-y-4 lg:items-end">
            <Link
              className="hover:underline"
              href="/site-portal-terms"
              data-testid="link-terms"
            >
              {t('terms')}
            </Link>
            <Link
              className="hover:underline"
              href="/privacy-policy"
              data-testid="link-privacy"
            >
              {t('privacy')}
            </Link>
            <div data-testid="copyrights">{t('copyrights')}</div>
          </div>
        </div>
      </div>
    </footer>
  );
}
