import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

export default function Section(props: Props) {
  const { children } = props;

  return <section className="flex flex-col gap-5">{children}</section>;
}
