import { ImageLoaderProps, ImageProps } from 'next/image';

export const getImageLoader = (src: ImageProps['src']) => {
  if (src == null || typeof src !== 'string') {
    return undefined;
  }

  if (src.includes('inventory-service-assets')) {
    return inventoryAssetsImageLoader;
  }

  if (src.startsWith('/public-s3')) {
    return publicAssetsImageLoader;
  }

  return undefined;
};

const inventoryAssetsImageLoader = ({
  src,
  width,
  quality,
}: ImageLoaderProps) => {
  return `${src}?width=${width}&quality=${quality || 100}&format=webp`;
};

const publicAssetsImageLoader = ({ src, width, quality }: ImageLoaderProps) => {
  return `${src.replace(
    '/public-s3',
    `https://${process.env.NEXT_PUBLIC_ASSETS_DOMAIN!}`
  )}?width=${width}&quality=${quality || 100}&format=webp`;
};
