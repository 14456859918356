import clsx from 'clsx';

export function ButtonAsLink(props: React.ComponentPropsWithoutRef<'button'>) {
  const { className, children, type = 'button', ...others } = props;

  return (
    <button
      {...others}
      type={type}
      className={clsx(
        'text-teal-50 text-base underline hover:no-underline cursor-pointer',
        className
      )}
    >
      {children}
    </button>
  );
}
