import ChevronIcon from '../icons/ChevronIcon';

import clsx from 'clsx';

type ArrowButtonProps = {
  isDisabled?: boolean;
  direction: 'left' | 'right';
  className?: string;
  onClick: (direction: 'left' | 'right') => void;
} & React.ComponentPropsWithoutRef<'button'>;

export function ArrowButton({
  isDisabled,
  direction,
  className,
  onClick,
  ...props
}: ArrowButtonProps) {
  return (
    <button
      className={clsx(
        'flex items-center justify-center min-w-[48px] h-[48px] rounded-full bg-teal-5 disabled:bg-grey-60 not:hover:shadow',
        className
      )}
      disabled={isDisabled}
      onClick={() => onClick(direction)}
      {...props}
    >
      <ChevronIcon
        className={clsx(
          'stroke-white scale-150',
          direction === 'right' ? '-rotate-90' : 'rotate-90'
        )}
      />
    </button>
  );
}
