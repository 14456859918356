import { useTranslations } from 'next-intl';

import List from './List';
import LinkItemLink from './ListItemLink';
import Title from './ListTitle';
import Section from './Section';

export default function PartnershipSection() {
  const t = useTranslations('footer');

  return (
    <Section>
      <Title>{t('partnerships')}</Title>
      <List>
        <LinkItemLink href="/brokers" dataTestId="link-broker">
          {t('brokers')}
        </LinkItemLink>

        <LinkItemLink href="/enterprise" dataTestId="link-enterprise">
          {t('enterprise')}
        </LinkItemLink>

        <LinkItemLink href="/landlords " dataTestId="link-partnerships">
          {t('landlords')}
        </LinkItemLink>
      </List>
    </Section>
  );
}
