import { useTranslations } from 'next-intl';

import List from './List';
import ListItemLink from './ListItemLink';
import Title from './ListTitle';
import Section from './Section';

export default function MembersSection() {
  const t = useTranslations('footer');

  return (
    <Section>
      <Title>{t('members')}</Title>
      <List>
        <ListItemLink
          isExternal
          href="https://portal.industriousoffice.com"
          dataTestId="link-signin"
        >
          {t('signin')}
        </ListItemLink>
        <ListItemLink
          isExternal
          href="https://apps.apple.com/us/app/industrious-office/id1571362704"
          dataTestId="link-download-ios"
        >
          {t('download-ios')}
        </ListItemLink>
        <ListItemLink
          isExternal
          href="https://play.google.com/store/apps/details?id=com.industriousoffice.app"
          dataTestId="link-download-android"
        >
          {t('download-android')}
        </ListItemLink>
      </List>
    </Section>
  );
}
